import React, { useEffect } from 'react'
import { Link } from 'gatsby'
// Components
import {
  Primary as OrderButton,
  Basic as LoadMoreButton,
  Loading,
  OrderCard,
  PageHeader,
  Seo,
} from '../components'
// Context
import { usePrismic } from '../context/PrismicContext'
import { useAuthContext } from '../context/AuthContext'
import { useStore } from '../context/StoreContext'
// Styles
import { Wrapper, Container } from '../styles/common'

const OrderHistoryPage = () => {
  const { isEventSite } = useAuthContext()

  const {
    ordersData,
    ordersPageInfo,
    isLoadingOrders,
    isLoadingMoreOrders,
    handleGetPageDataAndOrders,
    handleGetNextPageOrders,
  } = useStore()

  const {
    prismicData: { prismicOrderHistoryPage, prismicCartPage },
  } = usePrismic()

  const {
    load_more,
    loading_orders,
    title,
    your_orders,
    no_orders,
    end_of_orders,
  } = prismicOrderHistoryPage

  const { keep_shopping } = prismicCartPage

  useEffect(() => {
    handleGetPageDataAndOrders()
  }, [])

  const isLastPage = ordersPageInfo?.current_page === 1

  if (isLoadingOrders)
    return (
      <Loading
        loading={true}
        message={loading_orders[0].text}
        showBg={isEventSite}
      />
    )

  return (
    <>
      <Seo title={title[0].text} />
      <Wrapper>
        <PageHeader exitRoute="/">{your_orders[0].text}</PageHeader>
        {!ordersData.length ? (
          <Container margin="4em 0 0 0" align="center" justify="center">
            <h1>{no_orders[0].text}</h1>

            <OrderButton data-qa="orderButton" as={Link} to="/products">
              {keep_shopping[0].text}
            </OrderButton>
          </Container>
        ) : (
          <Container>
            {!isLoadingOrders &&
              ordersData.map((order, number) => (
                <OrderCard key={number} {...order} />
              ))}
            {isLastPage ? (
              <Container margin="4em 0" align="center" justify="center">
                <h1>{end_of_orders[0].text}</h1>
              </Container>
            ) : (
              <LoadMoreButton
                style={{ alignSelf: 'center' }}
                onClick={handleGetNextPageOrders}
                loading={isLoadingMoreOrders}
                data-qa="loadMoreButton"
              >
                {load_more[0].text}
              </LoadMoreButton>
            )}
          </Container>
        )}
      </Wrapper>
    </>
  )
}

export default OrderHistoryPage
